import React, { Dispatch, SetStateAction } from 'react';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { ModalData, modalSelector } from '../lib/appState';
import {
  OrganisationModalType,
  WebhookModalType,
} from '../types/organisations';
import { PortfolioModalType } from '../types/portfolio';
import { ReportModalType } from '../types/report';

type TemplateModalType =
  | 'CREATE_TEMPLATE'
  | 'DELETE_TEMPLATE'
  | 'EDIT_TEMPLATE';

/** For if and when we expand the modal functionality outside of portfolios */
export type ModalType =
  | PortfolioModalType
  | WebhookModalType
  | ReportModalType
  | OrganisationModalType
  | TemplateModalType
  | 'DELETE_LOAN'
  | null;

export type ModalReturn = {
  modalType: ModalType;
  setModalType: Dispatch<SetStateAction<ModalType>>;
  open: (type: ModalType, data?: Record<string, any>) => void;
  close: () => void;
  // recoil
  modalData: ModalData | null;
  setModalData: SetterOrUpdater<ModalData | null>;
};

const useModal = (): ModalReturn => {
  const [modalType, setModalType] = React.useState<ModalType>(null);
  const [modalData, setModalData] = useRecoilState(modalSelector);

  const open = (type: ModalType, data?: Record<string, any>) => {
    setModalType(type);
    setModalData({ modalType: type, ...data });
  };

  const close = () => {
    setModalType(null);
    setModalData(null);
  };

  return {
    modalType,
    setModalType,
    open,
    close,
    // recoil
    modalData,
    setModalData,
  };
};

export default useModal;
