import { NextApiResponse } from 'next';

import { HandlerReturn } from '../../types/http';
import {
  HttpStatusCodes,
  StatusCodeConstants,
} from '../../types/http-status-codes';
import { GENERIC_API_ERROR } from './error-codes';

const { OK } = HttpStatusCodes;

/**
 * The Promise returned from fetch() won't reject on HTTP error
 * status even if the response is an HTTP 404 or 500. Instead,
 * as soon as the server responds with headers, the Promise will
 * resolve normally (with the ok property of the response set to
 * false if the response isn't in the range 200–299), and it will
 * only reject on network failure or if anything prevented the
 * request from completing.
 */

export const makeApiHandlerResponseSuccess = (
  args?: Partial<HandlerReturn>
): HandlerReturn => ({
  ok: args?.ok || true,
  status: args?.status || 200,
  is_error: false,
  error: '',
  message: OK.key,
});

export const makeApiHandlerResponseFailure = (
  args?: Partial<HandlerReturn>
): HandlerReturn => ({
  ok: args?.ok || false,
  status: args?.status || 500,
  message: args?.message || GENERIC_API_ERROR,
  is_error: true,
  error: args?.error || '',
});

export const makeMissingArgsResponse = (
  response: NextApiResponse,
  message: string,
  error: string,
  defaultProps: any
) => {
  return response.status(StatusCodeConstants.BAD_REQUEST).json({
    ...makeApiHandlerResponseFailure({
      message,
      error,
    }),
    ...defaultProps,
  });
};

export interface QueryParams {
  sort?: string;
  order?: string;
  start?: number;
  end?: number;
  search?: string;
  withCompanies?: boolean;
  owner_id?: string;
  filter?: string;
}

export const makeUrlQueryParams = (queryObj: QueryParams) => {
  const { sort, order, start, end, search, owner_id = '', filter } = queryObj;

  const query = new URLSearchParams();

  if (sort) {
    query.append('sort', sort);
  }

  if (order) {
    query.append('order', order);
  }

  if (`start` in queryObj && typeof start === `number`) {
    query.append('start', start.toString());
  }

  if (`end` in queryObj && typeof end === `number`) {
    query.append('end', end.toString());
  }

  if (search) {
    query.append('search', search);
  }

  if (owner_id) {
    query.append('owner_id', owner_id);
  }

  if (filter) {
    query.append('filter', filter);
  }

  return query.toString();
};

export type SetState = React.Dispatch<React.SetStateAction<QueryParams>>;

export type HandleQueryParams = (
  setState: SetState
) => (queryObj: QueryParams) => void;

/**
 *
 * @param setState
 * @desc this function is an initialiser to be used in several hooks, each relating to its own version of the query params
 * @example
 * // in the hook file
 * const [allPortfolioQuery, setAllPortfolioQuery] = React.useState<QueryParams>(initialiseQueryParams);
 *
 * const handleSortAllPortfolios = handleQueryParams(setAllPortfolioQuery);
 *
 * // in the component file
 * handleSortAllPortfolios({ sort: 'name', order: 'asc', start: 0, end: 10, search: '' });
 */

export const handleQueryParams: HandleQueryParams = setState => queryObj => {
  return setState(prev => ({
    ...prev,
    ...queryObj,
  }));
};
